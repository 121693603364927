<template>
  <div
    class="legal_management"
    style="overflow: hidden; padding: 5px 10px 10px 10px"
  >
    <div class="header">
      {{ $t("add_legal.add_legal") }}
    </div>
    <div v-if="!isLoading">
      <table>
        <tr>
          <th>{{ $t("admin.title") }}</th>
          <th>{{ $t("admin.description") }}</th>
          <th>{{ $t("admin.edit") }}</th>
        </tr>
        <tr>
          <td>
            <div
              v-if="lang == 'ka'"
              class="test"
              v-html="allLegalStatement.titleKA"
            ></div>
            <div v-else class="test" v-html="allLegalStatement.titleEN"></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              ref="descriptionKA"
              class="short_text"
              style="padding-left: 20px"
            ></div>
            <div
              v-else
              ref="descriptionEN"
              class="short_text"
              style="padding-left: 20px"
            ></div>
          </td>
          <td>
            <b-button
              @click="
                (modalShow = !modalShow),
                  editLegalStatement(
                    allLegalStatement._id,
                    allLegalStatement.descriptionKA,
                    allLegalStatement.descriptionEN,
                    allLegalStatement.titleKA,
                    allLegalStatement.titleEN
                  )
              "
            >
              <i class="fal fa-pen"></i>
            </b-button>
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="3"
        :columns="3"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>

    <b-modal id="modal-xl-2" v-model="modalShow" size="xl" hide-footer>
      <div class="text_editor">
        <label for="">{{ $t("admin.title") }} (KA)</label>
        <vue-editor v-model="update.titleKA"></vue-editor>
        <label for="">{{ $t("admin.title") }} (EN)</label>
        <vue-editor v-model="update.titleEN"></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("admin.description") }} (KA)</label>
        <vue-editor v-model="update.descriptionKA"></vue-editor>
        <label for="">{{ $t("admin.description") }} (EN)</label>
        <vue-editor v-model="update.descriptionEN"></vue-editor>
      </div>
      <button
        type="submit"
        class="submit btn btn-primary"
        @click="UpdateLegalStatement()"
      >
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  name: "getLegalStatement",
  components: {
    VueEditor,
    spinner,
  },
  data() {
    return {
      modalShow: false,
      allLegalStatement: [],
      active_id: "",
      update: {
        descriptionKA: "",
        descriptionEN: "",
        titleKA: "",
        titleEN: "",
      },
      isLoading: false,
      lang: "ka",
    };
  },
  mounted() {
    this.getLegalStatement();
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
  methods: {
    getLegalStatement() {
      this.isLoading = true;
      axios.get(`${env.host}/get/informations/legal`).then((res) => {
        this.isLoading = false;
        this.$nextTick(() => {
          this.$refs.descriptionKA.insertAdjacentHTML(
            "beforeend",
            this.allLegalStatement.descriptionKA
          );
        });
        this.$nextTick(() => {
          this.$refs.descriptionEN.insertAdjacentHTML(
            "beforeend",
            this.allLegalStatement.descriptionEN
          );
        });
        this.allLegalStatement = res.data.item;
      });
    },
    editLegalStatement(id, descriptionKA, descriptionEN, titleKA, titleEN) {
      this.active_id = id;
      this.update.descriptionKA = descriptionKA;
      this.update.descriptionEN = descriptionEN;
      this.update.titleKA = titleKA;
      this.update.titleEN = titleEN;
    },
    UpdateLegalStatement() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("descriptionKA", this.update.descriptionKA);
      formData.append("descriptionEN", this.update.descriptionEN);
      formData.append("titleKA", this.update.titleKA);
      formData.append("titleEN", this.update.titleEN);
      axios
        .post(`${env.host}/edit/informations/legal`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getLegalStatement();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
};
</script>


<style scoped>
.legal_management {
  width: 100%;
}
label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
.short_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
button,
.header {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.header {
  color: white;
  width: 50%;
  margin-bottom: 10px;
}
i {
  color: white;
}
table {
  width: 100%;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}

td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px;
}
th,
td {
  padding: 1em;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}
tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0px;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #ddd;
  }
  td {
    display: table-cell;
  }
  td::before {
    content: none;
  }
  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0px;
  }
  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>